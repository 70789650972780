import '../overlay'

import { hasAttr, getAttr } from './utils.attrs'
import { isEmpty, defaultTo, toBoolean } from './utils.bits'

import {
  OVERLAY_CLASS,
  BOOTSTRAPPED_ATTR,
  GALLERY_MIN_HEIGHT
} from '../src/utils/shared-constants'

export default function launchIncludeGallery(container) {
  if (hasAttr(container, BOOTSTRAPPED_ATTR)) {
    return
  }

  const useOverlay = readBooleanAttr(container, 'data-use-bootstrap-overlay', true)
  if (useOverlay) {
    if (isEmpty(container.style.position)) {
      container.style.position = 'relative'
    }

    if (!container.querySelector(`.${OVERLAY_CLASS}`)) {
      container.innerHTML = `<div class="${OVERLAY_CLASS}"></div>`
    }
  }

  const setMinHeight = readBooleanAttr(container, 'data-set-min-height', true)
  if (setMinHeight && isEmpty(container.style.minHeight)) {
    const stretchToParent = readBooleanAttr(container, 'data-stretch-background')
    const minHeight = stretchToParent ? '100%' : `${GALLERY_MIN_HEIGHT}px`
    container.style.minHeight = minHeight
  }

  return importBootstrapper().then(({ default: ggsBootstrap }) => {
    return ggsBootstrap(container)
  })
}

function readBooleanAttr(container, attrName, defaultValue = false) {
  return defaultTo(toBoolean(getAttr(container, attrName)), defaultValue)
}

function importBootstrapper() {
  return import('../src/root' /* webpackChunkName: "gallery-v2.main" */)
}
