/* https://webpack.js.org/api/module-variables */
/* global __resourceQuery */
/* global __webpack_public_path__ */ /* eslint-disable-line no-unused-vars */

'use strict'

if (module.id) {
  const publicPathMatch = __resourceQuery.match(/^\?publicPath=(.*)/)
  const publicPath = decodeURIComponent(((publicPathMatch || [])[1] || '').trim())
  if (publicPath.length === 0) {
    throw new Error(`${__filename} publicPath is not found in ${__resourceQuery}!`)
  }

  // the only way to define output.publicPath per entry (TODO: review after migration to webpack@5)
  // https://webpack.js.org/guides/public-path/#on-the-fly
  // https://webpack.js.org/configuration/output/#outputpublicpath
  __webpack_public_path__ = publicPath /* eslint-disable-line no-global-assign */
} else {
  throw new Error(`${__filename} should be injected into a webpack bundle!`)
}
