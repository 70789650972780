import { trim } from './utils.bits'

export function hasAttr(elm, attrName) {
  const attrNames = genAttrNames(attrName)
  for (const name of attrNames) {
    if (elm.hasAttribute(name)) {
      return true
    }
  }
  return false
}

export function getAttr(elm, attrName) {
  const attrNames = genAttrNames(attrName)
  for (const name of attrNames) {
    if (elm.hasAttribute(name)) {
      return elm.getAttribute(name)
    }
  }
  return null
}

export function setAttr(elm, attrName, attrVal) {
  const shortName = trim(attrName).replace(/(ggs-|data-)/g, '')
  const fullName = 'data-' + shortName
  return elm.setAttribute(fullName, attrVal)
}

export function genAttrNames(attrName) {
  const shortName = trim(attrName).replace(/(ggs-|data-)/g, '')
  return [
    shortName,
    'ggs-' + shortName,
    'data-' + shortName,
    'data-ggs-' + shortName
  ]
}
