import {
  get, isEmpty, random, isArray, toPairs,
  includes, uniqueId, isString, isElement,
  kebabCase, camelCase, castArray, defaultTo, isPlainObject
} from './utils.bits'

export function hex2rgb(hex = 'ffffff', opacity = 1) {
  hex = hex.replace(/^#/, '')
  const num = parseInt(hex, 16)
  const r = num >> 16 // eslint-disable-line no-bitwise
  const g = num >> 8 & 255 // eslint-disable-line no-bitwise
  const b = num & 255 // eslint-disable-line no-bitwise
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function isLikeIE() {
  const isIEBrowser = !!window.navigator.userAgent.match(/MSIE/) || !!window.navigator.userAgent.match(/Trident.*rv:11\./)
  const isMsEdgeBrowser = !!window.navigator.userAgent.match(/edg([ea]|ios)/i)
  return isIEBrowser || isMsEdgeBrowser
}

const STYLE = 'style'
const QUERY_RE = /^([^#.]+)?(?:#([^.]+))?(?:\.(.+))?$/
const PROPERTIES = ['id', 'className', 'innerHTML', 'value']

export function dom(...args) {
  if (!isString(args[0])) args.splice(0, 0, 'div')
  if (!isPlainObject(args[1])) args.splice(1, 0, {})
  if (isElement(args[2])) args[2] = castArray(args[2])
  if (!isArray(args[2])) args.splice(2, 0, [])

  const [query, attrs, children] = args
  if (args.length !== 3) throw new Error('dom: args - unexpected input!')
  if (!isString(query)) throw new Error('dom: query - unexpected input!')
  if (!isPlainObject(attrs)) throw new Error('dom: attrs - unexpected input!')
  if (!isArray(children)) throw new Error('dom: children - unexpected input!')

  const match = QUERY_RE.exec(query)
  const tag = defaultTo(get(match, 1), 'div')
  const id = get(match, 2)
  const className = get(match, 3)

  const element = document.createElement(tag)
  if (isString(id)) element.id = id
  if (isString(className)) element.className = className.replace(/\./g, ' ')

  for (const [key, val] of toPairs(attrs)) {
    if (includes(PROPERTIES, key)) {
      element[key] = val
    } else if (key === STYLE) {
      style(element, val)
    } else {
      element.setAttribute(key, val)
    }
  }

  for (const child of children) {
    element.appendChild(child)
  }

  return element
}

const NUMBER_RE = /^(-?\d+(?:\.\d+)?)$/
const PX_KEYS = [
  'top',
  'left',
  'right',
  'bottom',
  'width',
  'height',
  'max-width',
  'min-width',
  'max-height',
  'min-height',
  'margin',
  'margin-top',
  'margin-left',
  'margin-right',
  'margin-bottom',
  'padding',
  'padding-top',
  'padding-left',
  'padding-right',
  'padding-bottom'
]

export function style(...elms) {
  const styles = elms.pop()
  for (const elm of elms) {
    for (const [key, val] of toPairs(styles)) {
      const cssKey = kebabCase(key)
      if (isEmpty(val)) {
        elm.style.setProperty(cssKey, undefined)
        elm.style.setProperty(cssKey, '')
      } else {
        const requiresPx = includes(PX_KEYS, cssKey) && NUMBER_RE.test(val)
        const updatedVal = requiresPx ? `${val}px` : val
        elm.style.setProperty(cssKey, updatedVal)
      }
    }
  }
}

export function removeElm(elm) {
  if (elm.parentNode) {
    elm.parentNode.removeChild(elm)
  }
}

const MARKER_SUFFIX = '-' + random(1e5, 1e6).toString(36)

export function onAppend(selector, callback) {
  const marker = uniqueId(camelCase(selector)) + MARKER_SUFFIX

  const animationName = 'gleam-on-append-animation-name-' + marker
  const animationCss = '@keyframes ' + animationName + ' { 0% { visibility: inherit; } }'
  const triggerCss = selector + ' { animation: 1ms ' + animationName + '; }'
  const styleCss = animationCss + '\n' + triggerCss
  const styleId = 'gleam-on-append-id-' + marker
  injectStyle(styleId, styleCss)

  const eventNames = ['webkitAnimationStart', 'mozanimationstart', 'oanimationstart', 'animationstart']
  for (const eventName of eventNames) {
    document.addEventListener(eventName, triggerHandler)
  }

  function triggerHandler(event) {
    const elm = event.target
    if (!elm || elm[marker]) {
      return
    }

    let name = null
    if (event.animationName) {
      name = event.animationName
    } else {
      const styles = window.getComputedStyle(elm)
      name = styles && styles.getPropertyValue('animation-name')
    }

    if (name && name.indexOf(animationName) !== -1) {
      elm[marker] = true
      callback(elm)
    }
  }
}

export function injectStyle(id, css) {
  if (document.getElementById(id)) {
    return
  }

  const style = document.createElement('style')
  style.type = 'text/css'
  style.id = id
  style.appendChild(document.createTextNode(css))
  document.head.appendChild(style)
  return style
}
