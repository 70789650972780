import words from './utils.words'

export function get(obj, path) {
  const pathPartRE = /(.+?)\./

  if (!isObjectLike(obj)) {
    return undefined
  }

  const isPathPart = pathPartRE.test(path)
  if (!isPathPart) {
    return obj[path]
  }

  const part = last(pathPartRE.exec(path))
  return get(obj[part], path.replace(pathPartRE, ''))
}

export function last(val) {
  if (isArrayLikeObject(val)) {
    return val[val.length - 1]
  }
}

export function trim(val) {
  if (!isString(val)) {
    return ''
  }

  return val.replace(/^\s+|\s+$/g, '')
}

export function clamp(min, val, max) {
  if (min > max) {
    const tmp = max
    max = min
    min = tmp
  }

  return Math.max(min, Math.min(val, max))
}

export function isNaN(val) {
  return isNumber(val) && val != +val /* eslint-disable-line eqeqeq */
}

export function isNull(val) {
  return val === null
}

export function assign(target, source) {
  for (const [key, value] of Object.entries(source)) {
    target[key] = value
  }

  return target
}

export function random(first, second) {
  if (isFinite(first) && isFinite(second)) {
    return first + Math.floor(Math.random() * (second - first + 1))
  } else if (isFinite(first) && !isFinite(second)) {
    return random(0, second)
  } else {
    return random(0, 1)
  }
}

export function isEmpty(val) {
  return false
    || isNull(val)
    || isUndefined(val)
    || isNaN(val)
    || (isString(val) && trim(val).length === 0)
}

export function isArray(val) {
  return Array.isArray(val)
}

export function toPairs(val) {
  if (!isObjectLike(val)) {
    return []
  }

  const pairs = []
  for (const key of Object.keys(val)) {
    pairs.push([key, val[key]])
  }
  return pairs
}

export function isNumber(val) {
  return typeof val === 'number'
}

export function isString(val) {
  return typeof val === 'string'
}

export function includes(arr, val) {
  if (!isArray(arr)) {
    return false
  }

  return arr.indexOf(val) !== -1
}

export function throttle(action, delay) {
  let lastCall = null
  let isNextPending = false

  function callAction() {
    lastCall = Date.now()
    action()
  }

  function canCallAction() {
    return !lastCall || (Date.now() >= (lastCall + delay))
  }

  function pendingNext() {
    isNextPending = true
    setTimeout(() => {
      isNextPending = false
      if (canCallAction()) callAction()
    }, delay)
  }

  function canPendingNext() {
    return !isNextPending
  }

  return function throttleWrapper() {
    if (canCallAction()) callAction()
    else if (canPendingNext()) pendingNext()
  }
}

export function uniqueId(prefix) {
  if (!uniqueId.__id__) {
    uniqueId.__id__ = 1
  } else {
    uniqueId.__id__++
  }

  if (isEmpty(prefix)) {
    return uniqueId.__id__
  } else {
    return prefix + uniqueId.__id__
  }
}

export function defaultTo() {
  const lastIndex = arguments.length - 1
  for (let index = 0; index !== lastIndex; index++) {
    const arg = arguments[index]
    if (!isEmpty(arg)) {
      return arg
    }
  }
  return arguments[lastIndex]
}

export function toBoolean(val) {
  if (val === true || val === false) {
    return val
  } else if (val === 'true' || val === 't') {
    return true
  } else if (val === 'false' || val === 'f') {
    return false
  } else {
    return null
  }
}

export function isElement(val) {
  return isObjectLike(val) && val.nodeType === 1
}

export function kebabCase(str) {
  return words(str).join('-').toLowerCase()
}

export function camelCase(str) {
  const chunks = words(str)
  let result = ''
  for (let index = 0, length = chunks.length; index !== length; index++) {
    const chunk = chunks[index].toLowerCase()
    if (index === 0) {
      result += chunk
    } else {
      result += chunk.slice(0, 1).toUpperCase() + chunk.slice(1)
    }
  }
  return result
}

export function castArray(val) {
  if (isArray(val)) {
    return val
  } else {
    return [val]
  }
}

export function isFunction(val) {
  return typeof val === 'function'
}

export function isUndefined(val) {
  return val === undefined
}

export function isObjectLike(val) {
  return typeof val === 'object' && val !== null
}

export function compactObject(obj) {
  const res = {}
  for (const [key, val] of toPairs(obj)) {
    if (!isEmpty(val)) {
      res[key] = val
    }
  }
  return res
}

export function isPlainObject(val) {
  if (!isObjectLike(val)) {
    return false
  }

  if (Object.prototype.toString.call(val) !== '[object Object]') {
    return false
  }

  const proto = Object.getPrototypeOf(val)
  if (proto === null) {
    return true
  }

  const Ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor
  return true
    && typeof Ctor === 'function'
    && Ctor instanceof Ctor
    && Function.prototype.toString.call(Ctor) === Function.prototype.toString.call(Object)
}

export function isArrayLikeObject(val) {
  return isObjectLike(val) && isNumber(val.length)
}
