import { toPairs, defaultTo } from './utils.bits'

export function parse() {
  const str = document.location.search.replace(/\+/g, ' ')
  const obj = {}
  const re = /[?&]?([^=&?]+)=?([^&?]*)/g

  let match = null
  while (!!(match = re.exec(str))) {
    const key = decodeURIComponent(match[1])
    const val = decodeURIComponent(defaultTo(match[2], ''))
    obj[key] = val
  }

  return obj
}

export function stringify(obj) {
  const pairs = []

  for (const [key, val] of toPairs(obj)) {
    const encodedKey = encodeURIComponent(key)
    const encodedVal = encodeURIComponent(val)
    pairs.push(`${encodedKey}=${encodedVal}`)
  }

  return pairs.join('&')
}
