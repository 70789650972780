
      var API = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
      var domAPI = require("!../../../node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js");
      var insertFn = require("!../../../node_modules/style-loader/dist/runtime/insertBySelector.js");
      var setAttributes = require("!../../../node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js");
      var insertStyleElement = require("!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js");
      
      var content = require("!!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/less-loader/dist/cjs.js??clonedRuleSet-24.use[3]!./index.less");
      
      content = content.__esModule ? content.default : content;

var options = {"attributes":{"gleam-shared-style-element":true}};

;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



module.exports = content && content.locals || {};
