/* global webpack */

import '../overlay'

import * as qs from './utils.qs'
import ResizeDetector from 'element-resize-detector'

import { hasAttr, getAttr, setAttr } from './utils.attrs'
import { dom, style, hex2rgb, isLikeIE, removeElm } from './utils.dom'
import { get, clamp, toPairs, throttle, toBoolean, defaultTo, isFunction, isPlainObject, compactObject } from './utils.bits'

import {
  OVERLAY_CLASS,
  GALLERY_MIN_HEIGHT
} from '../src/utils/shared-constants'

const OVERLAY_QS = `.${OVERLAY_CLASS}`

const CHECK_INTERVAL = 1e3
const MIN_READY_SPACE = 3000

const POSITION_EVENT = 'GalleryPosition'
const LOAD_MORE_EVENT = 'LoadMoreGalleryItems'
const CONTAINER_SIZE_EVENT = 'GalleryContainerSize'

const OVERFLOW_VISIBLE = 'visible'

export default function launchEmbedGallery(container) {
  if (!hasAttr(container, 'launched')) {
    setAttr(container, 'launched', true)
    new EmbedGallery(container)
  }
}

class EmbedGallery {
  constructor(container) {
    this.container = container

    this.key = getAttr(this.container, 'gallery-embed')
    this.devIframeSrc = getAttr(this.container, 'dev-iframe-src')
    this.inScrollable = toBoolean(getAttr(this.container, 'in-scrollable'))
    this.liveEditMode = toBoolean(getAttr(this.container, 'live-edit-mode'))

    this.iframe = null
    this.overlay = null
    this.background = null
    this.scrollable = this.findScrollable()

    this.iframeRect = null
    this.containerRect = null
    this.scrollableRect = null
    this.viewportHeight = null

    this.receivedGalleryHeight = null

    this.params = compactObject({
      gallery_key: this.key,
      l: document.location.href,
      h: getAttr(this.container, 'header'),
      e: getAttr(this.container, 'enter'),
      s: getAttr(this.container, 'sorting'),
      o: getAttr(this.container, 'sort'),
      d: defaultTo(getAttr(this.container, 'displayed-item'), qs.parse().d),
      i: defaultTo(getAttr(this.container, 'displayed-slide'), qs.parse().i),
      preview: getAttr(this.container, 'gallery-preview'),
      strict_order: getAttr(this.container, 'strict-order'),
      force_hosted_share_link: getAttr(this.container, 'force-hosted-share-link')
    })

    this.boundOnLoad = this.onLoad.bind(this)
    this.boundOnMessage = this.onMessage.bind(this)
    this.boundOnInterval = this.onInterval.bind(this)
    this.boundOnContainerResize = this.onContainerResize.bind(this)
    this.boundOnScrollableResize = this.onScrollableResize.bind(this)

    this.throttledOnPageScroll = throttle(this.onPageScroll.bind(this), 50)
    this.throttledOnViewportResize = throttle(this.onViewportResize.bind(this), 50)
    this.throttledOnScrollableScroll = throttle(this.onScrollableScroll.bind(this), 50)

    this.intervalId = setInterval(this.boundOnInterval, CHECK_INTERVAL)
    window.addEventListener('message', this.boundOnMessage)
    window.addEventListener('scroll', this.throttledOnPageScroll)
    window.addEventListener('resize', this.throttledOnViewportResize)
    this.resizeDetector = ResizeDetector({ strategy: 'scroll', important: true })
    this.resizeDetector.listenTo(this.container, this.boundOnContainerResize)

    if (this.scrollable) {
      this.scrollable.addEventListener('scroll', this.throttledOnScrollableScroll)
      this.resizeDetector.listenTo(this.scrollable, this.boundOnScrollableResize)
    }

    this.measure()
    this.render()
  }

  destroy() {
    clearInterval(this.intervalId)
    window.removeEventListener('message', this.boundOnMessage)
    window.removeEventListener('scroll', this.throttledOnPageScroll)
    window.removeEventListener('resize', this.throttledOnViewportResize)
    this.resizeDetector.uninstall(this.container)

    if (this.scrollable) {
      this.scrollable.removeEventListener('scroll', this.throttledOnScrollableScroll)
      this.resizeDetector.uninstall(this.scrollable)
    }
  }

  makeIframeSrc() {
    const host = webpack.apiServerHost
    const path = this.liveEditMode ? 'js-gallery-live-edit' : 'js-gallery-embed'
    const query = qs.stringify(this.params)
    return host + '/' + path + '?' + query
  }

  measure() {
    this.containerRect = this.container.getBoundingClientRect()
    this.viewportHeight = window.innerHeight

    if (this.iframe) {
      this.iframeRect = this.iframe.getBoundingClientRect()
    }

    if (this.scrollable) {
      this.scrollableRect = this.scrollable.getBoundingClientRect()
    }
  }

  render() {
    this.background = dom('div')

    this.iframe = dom('iframe', {
      src: defaultTo(this.devIframeSrc, this.makeIframeSrc()),
      allow: 'autoplay',
      frameBorder: '0',
      allowfullscreen: 'true'
    })

    this.overlay = this.container.querySelector(OVERLAY_QS) ?? dom(OVERLAY_QS)

    style(this.background, {
      overflow: 'hidden',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
    })

    style(this.iframe, {
      position: 'relative',
      display: 'block',
      width: '100%',
      minHeight: this.getMinHeight()
    })

    style(this.container, {
      position: 'relative'
    })

    if (this.liveEditMode) {
      style(this.iframe, this.container, {
        height: '100%'
      })
    }

    this.container.appendChild(this.background)
    this.container.appendChild(this.iframe)
    this.container.appendChild(this.overlay)

    this.iframe.addEventListener('load', this.boundOnLoad)
  }

  onLoad() {
    setTimeout(() => {
      this.measure()
      this.updatePosition()
      this.sendContainerSize()
      removeElm(this.overlay)
    }, 200)
  }

  onInterval() {
    if (document.body.contains(this.container)) {
      this.measure()
      this.updatePosition()
      this.sendContainerSize()
    } else {
      this.destroy()
    }
  }

  onPageScroll() {
    this.measure()
    this.updatePosition()
  }

  onViewportResize() {
    this.measure()
    this.updatePosition()
  }

  onContainerResize() {
    this.measure()
    this.updatePosition()
    this.sendContainerSize()
  }

  onScrollableScroll() {
    this.measure()
    this.updatePosition()
  }

  onScrollableResize() {
    this.measure()
    this.updatePosition()
    this.updateMinHeight()
  }

  onMessage({ data, source }) {
    if (this.iframe.contentWindow !== source) {
      return
    }

    const re = /(.+?)(Gallery[a-zA-Z]+)(.*)?/
    const match = re.exec(data)
    if (!match || match[1] !== this.key) {
      return
    }

    const cmd = match[2]
    const arg = match[3]
    switch (cmd) {
    case 'GalleryReady':
      return this.receiveReady(arg)
    case 'GalleryLoaded':
      return this.receiveLoaded(arg)
    case 'GalleryResize':
      return this.receiveHeight(arg)
    case 'GalleryVoteUp':
      return this.receiveVoteUpEvent(arg)
    case 'GalleryBackground':
      return this.receiveBackgroundConfig(arg)
    }
  }

  receiveReady() {
    this.container.setAttribute('data-ready', true)
  }

  receiveLoaded() {
    this.container.setAttribute('data-loaded', true)
  }

  receiveHeight(height) {
    this.receivedGalleryHeight = parseInt(height)
    this.updateHeight()
  }

  receiveVoteUpEvent() {
    this.setUserVotes()
  }

  receiveBackgroundConfig(bgConfig) {
    this.bgConfig = JSON.parse(bgConfig)
    this.updateBackground()
  }

  sendContainerSize() {
    this.postMessage(CONTAINER_SIZE_EVENT, {
      cw: this.containerRect.width,
      ch: this.containerRect.height
    })
  }

  postMessage(message, params) {
    if (isPlainObject(params)) {
      for (const [key, val] of toPairs(params)) {
        message += `:${key}=${val}`
      }
    }

    if (this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(message, '*')
    }
  }

  updateHeight() {
    if (this.liveEditMode) {
      return
    }

    style(this.iframe, {
      height: this.receivedGalleryHeight
    })
  }

  updateMinHeight() {
    style(this.iframe, {
      minHeight: this.getMinHeight()
    })
  }

  updatePosition() {
    if (this.liveEditMode) {
      return
    }

    const frameWidth = this.iframeRect.width
    const frameHeight = this.iframeRect.height
    const vportHeight = this.getLimitedViewportHeight()
    const vportOffset = this.getLimitedViewportOffset()
    const diff = frameHeight - vportOffset + vportHeight
    const readySpace = frameHeight - diff

    this.postMessage(POSITION_EVENT, {
      b: vportOffset,
      w: vportHeight,
      g: frameHeight,
      m: frameWidth
    })

    if (readySpace < MIN_READY_SPACE) {
      this.postMessage(LOAD_MORE_EVENT)
    }

    const topOffset = this.iframeRect.top
    const bottomOffset = vportHeight - this.iframeRect.bottom
    const limit = vportHeight * 0.3
    const isFitViewport = (topOffset >= 0) && (bottomOffset >= 0)
    const isFillViewport = ((topOffset <= 0) && (bottomOffset <= limit)) || ((topOffset <= limit) && (bottomOffset <= 0))
    const galleryIsInViewport = isFitViewport || isFillViewport

    this.setGalleryAppears(galleryIsInViewport)
  }

  getMinHeight() {
    if (this.scrollable) {
      return Math.max(GALLERY_MIN_HEIGHT, this.scrollableRect.height)
    } else {
      return GALLERY_MIN_HEIGHT
    }
  }

  findScrollable(candidate = this.container) {
    if (!this.inScrollable || !candidate || candidate === document.documentElement) {
      return null
    }

    const isScrollable = window.getComputedStyle(candidate).overflowY !== OVERFLOW_VISIBLE

    if (isScrollable) {
      return candidate
    } else {
      return this.findScrollable(candidate.parentElement)
    }
  }

  getLimitedViewportHeight() {
    if (this.scrollable) {
      return Math.min(this.viewportHeight, this.scrollableRect.height)
    } else {
      return this.viewportHeight
    }
  }

  getLimitedViewportOffset() {
    const { bottom: galleryBottom } = this.iframeRect

    // if scrollable is present, make the modal follow the viewport but stay within the scrollable
    if (this.scrollable) {
      const limitedViewportHeight = this.getLimitedViewportHeight()
      const { top: scrollableTop, bottom: scrollableBottom } = this.scrollableRect

      return clamp(
        galleryBottom - scrollableTop,
        galleryBottom,
        galleryBottom - (scrollableBottom - limitedViewportHeight)
      )
    } else {
      return galleryBottom
    }
  }

  updateBackground() {
    if (this.liveEditMode) {
      return
    }

    style(this.background, {
      marginTop: this.bgConfig.body_bg_apply_to_root ? 0 : this.bgConfig.header_height,
      backgroundColor: hex2rgb(this.bgConfig.body_background_color, this.bgConfig.body_bg_color_opacity)
    })

    if (!this.bgConfig.body_background_image) {
      return
    }

    if (this.bgConfig.body_bg_image_stretch && isLikeIE()) {
      return
    }

    style(this.background, {
      backgroundSize: this.bgConfig.body_bg_image_stretch ? 'cover' : 'unset',
      backgroundImage: 'url(' + this.bgConfig.body_background_image + ')',
      backgroundRepeat: this.bgConfig.body_bg_image_stretch ? 'no-repeat' : 'repeat',
      backgroundPosition: this.bgConfig.body_bg_image_stretch ? 'center' : 'top center',
      backgroundAttachment: this.bgConfig.body_bg_image_stretch ? 'fixed' : 'scroll'
    })
  }

  setUserVotes() {
    if (this.liveEditMode) {
      return
    }

    const galleriesState = get(window, '_app.galleriesState')
    if (isFunction(galleriesState)) {
      galleriesState(this.key).user_votes = true
    }
  }

  setGalleryAppears(gallery_appears) {
    if (this.liveEditMode) {
      return
    }

    const galleriesState = get(window, '_app.galleriesState')
    if (isFunction(galleriesState)) {
      galleriesState(this.key).gallery_appears = gallery_appears
    }
  }
}
