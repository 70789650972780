import inspect from 'object-inspect'
import supportsProxy from './supports-proxy'

const WHITE_LIST = [
  '__defineGetter__',
  '__defineSetter__',
  '__lookupGetter__',
  '__lookupSetter__',
  '__proto__',
  'constructor',
  'hasOwnProperty',
  'isPrototypeOf',
  'propertyIsEnumerable',
  'toLocaleString',
  'toString',
  'nodeName',
  'inspect',
  'indexOf',
  'valueOf',
  'toJSON',
  'length',
  'and',
  'tagName',
  'nodeType',
  'document',
  'outerHTML',
  'cloneNode',
  'asymmetricMatch',
  'jasmineToString',
  'finally',
  'then',
  'window',
  '$evalAsync',
  'emitterList',
  '__esModule',
  '_s',
  'state',
  // vue
  '_isVue',
  '__v_skip',
  '__v_isRef',
  '__ob__',
  'render',
  // vue-debugger
  'toDateString',
  'message',
  'flags',
  'throw'
]

export default supportsProxy()
  ? StrictObj
  : FakeStrictObj

function FakeStrictObj(obj) {
  return obj
}

function StrictObj(obj) {
  return new Proxy(obj, {
    get: function strictObj_get(target, property) {
      const error = true
        && !hasOwn(target, property)
        && !hasIn(target, property)
        && !isSymbol(property)
        && !includes(WHITE_LIST, property)
        && !isIndexLike(property)

      if (error) {
        throw new Error(`StrictObj: ${className(obj)} ${inspectShort(obj)} has not [property=${inspect(property)}] key!`)
      } else {
        return target[property]
      }
    }
  })
}

function hasOwn(target, property) {
  return target.hasOwnProperty(property)
}

function hasIn(target, property) {
  return property in target
}

function isIndexLike(v) {
  return /^\d+$/.test(v)
}

function inspectShort(v) {
  const limit = 300
  const inspected = inspect(v, { depth: 1 })
  if (inspected <= limit) {
    return inspected
  } else {
    return inspected.substr(0, 300) + '...'
  }
}

function className(v) {
  try {
    if (v.constructor && v.constructor.name) {
      return v.constructor.name
    } else {
      return Object.prototype.toString.call(v)
    }
  } catch (_err) {
    return `typeof=${typeof v}`
  }
}

function isSymbol(v) {
  return typeof v === 'symbol' || Object.prototype.toString.call(v) === '[object Symbol]'
}

function includes(arr, v) {
  return arr.indexOf(v) !== -1
}
