export default function supportsProxy() {
  if (typeof Proxy === 'undefined') {
    return false
  }

  try {
    const obj = { a: 'A' }
    const proxy = new Proxy(obj, {
      get(target, property) {
        return target[property] + '!'
      }
    })

    obj.b = 'B'
    proxy.c = 'C'

    return true
      && obj.a === 'A'
      && obj.b === 'B'
      && obj.c === 'C'
      && proxy.a === 'A!'
      && proxy.b === 'B!'
      && proxy.c === 'C!'
  } catch (_err) {
    return false
  }
}
